<template>
  <div>
    <b-card
      title="รายงานการจ่ายโปรโมชั่น"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="tableQuery.user"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="ชื่อ-สกุล"
                label-for="mc-last-name"
              >
                <b-form-input
                  id="mc-last-name"
                  v-model="tableQuery.fullname"
                  placeholder="ชื่อ-สกุล"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="โปรโมชั่น"
                label-for="mc-city"
              >
                <v-select
                  v-model="tableQuery.promotionId"
                  label="detail"
                  :options="promotions"
                  :reduce="option => option.id"
                  title="label"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="วันที่"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col md="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="fetchPaidPromotion"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row>
      <b-col
        xl="12"
        md="12"
      >
        <b-card
          class="card-statistics"
          no-body
        >
          <b-card-header>
            <b-card-title>สถิติรายการ</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated 1 mins ago
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in stats"
                :key="item.key"
                xl="4"
                sm="4"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title || '0.00' }} บาท
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.count || 0 }} รายการ
                    </b-card-text>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      title="รายงานการจ่ายโปรโมชั่น"
      no-body
    >
      <b-table
        ref="membertable"
        striped
        hover
        responsive
        class="position-relative"
        :busy.sync="isBusy"
        :per-page="tableQuery.page_size"
        :current-page="tableQuery.page"
        :items="items"
        :fields="fields"
      >
        <template #cell(username)="data">
          {{ data.item.phoneno }}<br>
          {{ data.item.username }}
        </template>
        <template #cell(fullname)="data">
          {{ `${data.item.fullname}` }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="tableQuery.page_size"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="tableQuery.page"
            :total-rows="totalRows"
            :per-page="tableQuery.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BRow, BCol, BForm, BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BCardHeader, BCardTitle, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    // BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    // Cleave,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
    BCardHeader,
    BCardTitle,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      pageOptions: [1000, 5000],
      totalRows: 1,
      tableQuery: {
        page: 1,
        page_size: 5000,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().format('YYYY-MM-DD 23:59:59'),
      },
      fields: [
        { key: 'received_date', label: 'วันที่เวลา', formatter: val => this.formatDate(val) },
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'detail', label: 'โปรโมชั่น' },
        {
          key: 'amount',
          label: 'จำนวน',
          tdClass: 'td-currency',
          formatter: val => this.numberWithCommas(val),
        },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      stats: [],
      promotions: [],
      promotionEvents: [],
      /* eslint-disable global-require */
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchPromotions()
    this.fetchPaidPromotion()
  },
  methods: {
    async fetchPromotions() {
      try {
        const { data } = await this.$http.get('promotions')
        if (data.success) {
          this.promotionEvents = data.promotionEvents
          this.promotions = data.promotions.data

          this.stats = []
          this.promotions.forEach(proItem => {
            this.stats.push({
              color: 'light-primary',
              customClass: 'mb-10 mb-xl-10',
              icon: 'DollarSignIcon',
              key: `pro${proItem.id}`,
              subtitle: proItem.title,
              title: '0.00',
            })
          })
        }
        console.log('promotion', data)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchPaidPromotion() {
      try {
        const { data: { success, paidPromotions, summaryGrouped } } = await this.$http.post('report/paid-promotions-logs', this.tableQuery)
        if (success) {
          this.items = paidPromotions
          // console.log('stats', this.stats)
          this.stats.forEach((item, idx) => {
            this.stats[idx].title = this.numberWithCommas(parseFloat((summaryGrouped[item.key] || {}).totalAmountPaid || 0).toFixed(2))
            this.stats[idx].count = (summaryGrouped[item.key] || {}).countRecord || 0
          })
          // const paidPromoSummary = [summary.pro_new_member || 0, summary.pro_daily_topup || 0, summary.pro_rolling || 0, summary.pro_invite || 0]
          // paidPromotions.forEach(paidItem => {
          //   if (moment(paidItem.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
          //     if (paidItem.detail.indexOf('สมาชิกใหม่') >= 0) {
          //       paidPromoSummary[0] += parseFloat(paidItem.amount)
          //     } else if (paidItem.detail.indexOf('เติมเงินครั้งแรก') >= 0) {
          //       paidPromoSummary[1] += parseFloat(paidItem.amount)
          //     } else if (paidItem.detail.indexOf('ROLLING') >= 0) {
          //       paidPromoSummary[2] += parseFloat(paidItem.amount)
          //     } else if (paidItem.detail.indexOf('INVITE') >= 0) {
          //       paidPromoSummary[3] += parseFloat(paidItem.amount)
          //     }
          //   }
          // })

          // this.stats[0].title = `${parseFloat(paidPromoSummary[0]).toFixed(2)}`
          // this.stats[1].title = `${parseFloat(paidPromoSummary[1]).toFixed(2)}`
          // this.stats[2].title = `${parseFloat(paidPromoSummary[2]).toFixed(2)}`
          // this.stats[3].title = `${parseFloat(paidPromoSummary[3]).toFixed(2)}`
        }
      } catch (err) {
        console.log(err)
      }
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 5000,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().format('YYYY-MM-DD 23:59:59'),
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.td-currency {
  text-align: right;
}
.mb-10 {
  margin-bottom:  20px;
}
</style>
